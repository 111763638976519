import React from "react"
import "./switch.css"

interface SwitchProps {
  name: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  [key: string]: any;
}

const Switch: React.FC<SwitchProps> = ({ checked, onChange, ...props }) => {
	return (
		<>
			<label className="switch">
				<input type="checkbox" checked={checked} onChange={onChange} {...props} />
				<span className="slider round"></span>
			</label>
		</>
	)
}

export default Switch
