import React from "react"
import "./Inputs.css"


interface CustomInputProps {
	htmlFor: string;
	name: string;
	placeholder?: string;
	value: string;
	disabled: boolean;
	handleChange: any;
	onBlur?: any;
	label?: string;
	style?: any;
	autoFocus?: boolean;
	errors: any;
	touched: any;
	description?:string;
	hideErrorMessage?: boolean;
}

export default function FormikInput(
	{name, placeholder, value, disabled, handleChange,
		htmlFor, onBlur, label, style, errors, touched, description, hideErrorMessage}
		: CustomInputProps) {

	return (
		<div
			style={style}
			className="simple_input">
			{label
				?
				<label
					className="simple_label"
					htmlFor={htmlFor}
				>
					{label}
				</label>
				: null
			}
			{
				description && description.length
					? <p className="sub_label" style={{margin: 0}}>
						{description}
					</p>
					: null
			}
			<input
				name={name}
				type="text"
				className={errors && touched ? "input error" : "input"}
				placeholder={placeholder}
				disabled = {disabled}
				value = {value}
				onChange={handleChange}
				onBlur={onBlur}
			/>
			{
				hideErrorMessage ? null : 
					(errors && touched && <p className="input_error">{errors}</p>)
			}
			{/* {errors && touched && <p className="input_error">{errors}</p>} */}
		</div>
	)

}
