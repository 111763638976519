import React, {useEffect, lazy, useState} from "react"
import {connect, useDispatch} from "react-redux"
import {useTranslation} from "react-i18next"
import {useFormik} from "formik"
import * as Yup from "yup"
import "./textForm.css"
import {Checkbox} from "antd"
import { changeValues } from "../../redux/actions/stepsActions"
import { setTooltip } from "../../redux/actions/tooltipActions"
import { RootState } from "../../redux/reducers/rootReducer"
import FormikTextField from "../inputs/formikTextField/FormikTextField"
import FormikInput from "../inputs/FormikInput"



interface CustomProps {
	stateValues?: any,
	setButtonDisabled: (state:boolean) => void,
	id: string,
	couponing?: boolean
}

function LocalTextForm ({stateValues, setButtonDisabled, id, couponing}:CustomProps) {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const formik = useFormik<any>({
		initialValues: {
			title: "",
			multipleTitle: false,
			titleDE: "",
			description: "",
			multipleDescription: false,
			descriptionDE: "",
			text: "",
			multipleText: false,
			textDE: "",
			highlights: "",
			multipleHighlights: false,
			highlightsDE: "",
			conditions: "",
			multipleConditions: false,
			conditionsDE: "",
			additionalConditions: "",
			multipleAdditionalConditions: false,
			additionalConditionsDE: "",
			merchant: "",
			multipleMerchant: false,
			merchantDE: "",
		},
		validationSchema: Yup.object({
			title: Yup.string().required(t("warnings.validation.required")).min(40,
				t("warnings.validation.minimal_length",
					{ name: t("text_form.title_title_error"), numbers: "40" }))
				.max(65,
					t("warnings.validation.maximal_length",
						{ name: t("text_form.title_title_error"), numbers: "65" })),
			titleDE: Yup.string().when(["multipleTitle"], (multipleTitle: boolean, schema) => {
				return multipleTitle ? schema.min(40,
					t("warnings.validation.minimal_length",
						{ name: t("text_form.title_title_error"), numbers: "40" }))
					.max(65,
						t("warnings.validation.maximal_length",
							{
								name:
									t("text_form.title_title_error"),
								numbers: "65" }))
					: schema.min(0).max(99999)
			}),
			description: Yup.string().required(t("warnings.validation.required")).min(95,
				t("warnings.validation.minimal_length",
					{ name: t("text_form.description_title_error"), numbers: "95" }))
				.max(125,
					t("warnings.validation.maximal_length",
						{ name: t("text_form.description_title_error"), numbers: "125" })),
			descriptionDE: Yup.string().when(["multipleDescription"],
				(multipleDescription: boolean, schema) => {
					return multipleDescription ? schema.min(95,
						t("warnings.validation.minimal_length",
							{ name: t("text_form.description_title_error"), numbers: "95" }))
						.max(125,
							t("warnings.validation.maximal_length",
								{
									name:
										t("text_form.description_title_error"),
									numbers: "125" }))
						: schema.min(0).max(99999)
				}),
			highlights: Yup.string().required(t("warnings.validation.required")).max(300,
				t("warnings.validation.maximal_length",
					{ name: t("text_form.highlights_title_error"), numbers: "300" })),
			highlightsDE: Yup.string().when(["multipleHighlights"],
				(multipleHighlights: boolean, schema) => {
					return multipleHighlights ? schema.max(300,
						t("warnings.validation.maximal_length",
							{ name: t("text_form.highlights_title_error"), numbers: "300" }))
						: schema.max(99999)
				}),
			text: Yup.string().required(t("warnings.validation.required")).min(500,
				t("warnings.validation.minimal_length",
					{ name: t("text_form.text_title_error"), numbers: "500" }))
				.max(700,
					t("warnings.validation.maximal_length",
						{ name: t("text_form.text_title_error"), numbers: "700" })),
			textDE: Yup.string().when(["multipleText"],
				(multipleText: boolean, schema) => {
					return multipleText ? schema.min(500,
						t("warnings.validation.minimal_length",
							{ name: t("text_form.text_title_error"), numbers: "500" }))
						.max(700,
							t("warnings.validation.maximal_length",
								{ name: t("text_form.text_title_error"), numbers: "700" }))
						: schema.max(99999)
				}),
		}),
		onSubmit: () => console.log("CLICK"),
	})

	useEffect(() => {
		const numberOfLines = (formik.values.highlights.match(/\n/g)||[]).length + 1
		if (formik.values.highlights.length) {
			if (numberOfLines < 4 || numberOfLines > 6) {
				setSubErrors({...subErrors,
					highlights: t("text_form.highlights_title_error_points")})
			} else {
				const objCopy:any = {...subErrors}
				delete objCopy["highlights"]
				setSubErrors(objCopy)
			}
		} else {
			const objCopy:any = {...subErrors}
			delete objCopy["highlights"]
			setSubErrors(objCopy)
		}
	}, [formik.values.highlights])

	// DE validation
	useEffect(() => {
		const numberOfLines = (formik.values.highlightsDE.match(/\n/g)||[]).length + 1
		if (formik.values.highlightsDE.length) {
			if (numberOfLines < 4 || numberOfLines > 6) {
				setSubErrors({...subErrors,
					highlights: t("text_form.highlights_title_error_points")})
			} else {
				const objCopy:any = {...subErrors}
				delete objCopy["highlights"]
				setSubErrors(objCopy)
			}
		} else {
			const objCopy:any = {...subErrors}
			delete objCopy["highlights"]
			setSubErrors(objCopy)
		}
	}, [formik.values.highlightsDE])

	const [subErrors, setSubErrors] = useState<any>({})

	// Save values from form to state
	useEffect(() => {
		const stateCopy = [...stateValues]
		stateCopy[2] = formik.values

		dispatch(changeValues(stateCopy, 0))
		if (
			Object.keys(formik.errors).length
			|| Object.keys(subErrors).length) {
			let filledLines = 0
			let emptyLines = 0
			Object.keys(formik.errors).forEach((key:string) => {
				if (formik.values[key]?.length) {
					filledLines += 1
				}  else {
					emptyLines += 1
				}
			})
			if (emptyLines > 0 && filledLines === 0
				 && !formik.errors.localMerchantName
				  && !formik.errors.localMerchantAddress) {
				setButtonDisabled(false)
			} else {
				setButtonDisabled(true)
			}
		} else {
			setButtonDisabled(false)
		}


		const isValid = formik.isValid

		if(!isValid){
			setButtonDisabled(true)
		}

	}, [formik.values, formik.errors])

	const [stateIsInitialized, setStateIsInitialized] = useState(false)

	// Initialize form values
	useEffect(() => {
		if (!stateIsInitialized && stateValues[2].startDate) {
			Object.keys(stateValues[2]).forEach((key:string) => {
				formik.setFieldValue(key, stateValues[2][key], true)
				setTimeout(() => formik.setFieldTouched(stateValues[2][key], true))
			})
		}
		setStateIsInitialized(true)
	}, [stateValues])

	const [formWithIdIsInitialized, setFormWithIdIsInitialized] = useState(false)

	// Initialize form values for updating
	useEffect(() => {
		if (stateValues[2] && !formWithIdIsInitialized) {
			if (stateValues[2].titleDE && stateValues[2].titleDE.length) {
				formik.setFieldValue("multipleTitle", true, true)
			}
			if (stateValues[2].descriptionDE && stateValues[2].descriptionDE.length) {
				formik.setFieldValue("multipleDescription", true, true)
			}
			if (stateValues[2].textDE && stateValues[2].textDE.length) {
				formik.setFieldValue("multipleText", true, true)
			}
			if (stateValues[2].highlightsDE && stateValues[2].highlightsDE.length) {
				formik.setFieldValue("multipleHighlights", true, true)
			}
			if (stateValues[2].conditionsDE && stateValues[2].conditionsDE.length) {
				formik.setFieldValue("multipleConditions", true, true)
			}
			if (stateValues[2].additionalConditionsDE
				&& stateValues[2].additionalConditionsDE.length) {
				formik.setFieldValue("multipleAdditionalConditions", true, true)
			}
			if (stateValues[2].merchantDE && stateValues[2].merchantDE.length) {
				formik.setFieldValue("multipleMerchant", true, true)
			}

			setFormWithIdIsInitialized(true)
			setStateIsInitialized(true)
			Object.keys(stateValues[2]).forEach((key:string) => {
				formik.setFieldValue(key, stateValues[2][key], true)
				setTimeout(() => formik.setFieldTouched(stateValues[2][key], true))
			})
		}
	}, [stateValues])

	// console.log(formik.errors)

	// console.log(formik.touched)

	return (
		<form className="form_container">

			{/*TITLE*/}
			<div
				style={{marginBottom: "20px"}}
				onMouseEnter={() => dispatch(setTooltip("title"))}
				onMouseLeave={() => dispatch(setTooltip(""))}
				data-testid="text_title"
			>
				<p className="simple_label">
					{t("text_form.title_title")}
				</p>
				<p className="sub_label">
					{t("text_form.title_text")}
				</p>
				<FormikTextField
					handleChange={formik.handleChange}
					value={formik.values.title}
					name="title"
					errors={formik.errors.title}
					touched={formik.touched.title}
					onBlur={formik.handleBlur}
				/>

				{stateValues[0].country.length === 2
					? <Checkbox
						style={{marginTop: "15px", marginLeft: "3px"}}
						checked={formik.values.multipleTitle}
						onChange={() => {
							formik.setFieldValue(
								"multipleTitle",
								!formik.values.multipleTitle,
								true
							)
						}}
					>
						{t("text_form.title_title_countries")}
					</Checkbox>
					: null
				}


				{formik.values.multipleTitle
					? <>
						<div style={{height: "10px"}} />
						<FormikTextField
							handleChange={formik.handleChange}
							value={formik.values.titleDE}
							name="titleDE"
							errors={formik.errors.titleDE}
							touched={formik.touched.titleDE}
							onBlur={formik.handleBlur}
						/>
					</>
					: null
				}

			</div>

			{/*MERCHANT*/}
			<div
				style={{marginBottom: "20px"}}
				onMouseEnter={() => dispatch(setTooltip("merchant"))}
				onMouseLeave={() => dispatch(setTooltip(""))}
				data-testid="merchant_title"
			>
				{/* <p className="simple_label">
					{t("text_form.merchant_title")}
				</p> */}

				 {/* REQUIRED */}
				 {/* <p className="sub_label" style={{marginBottom: "4px"}}>
					{t("text_form.local_merchant_text_1")}
				</p>
				<div className="merchant_container">
					<FormikTextField
						handleChange={formik.handleChange}
						value={formik.values.localMerchantName}
						name="localMerchantName"
						errors={formik.errors.localMerchantName}
						touched={formik.touched.localMerchantName}
						onBlur={formik.handleBlur}
					/>
				</div> */}
				{/* REQUIRED */}
				{/* <p className="sub_label merchant_label">
					{t("text_form.local_merchant_text_2")}
				</p>
				<div className="merchant_container">
					<FormikTextField
						handleChange={formik.handleChange}
						value={formik.values.localMerchantAddress}
						name="localMerchantAddress"
						errors={formik.errors.localMerchantAddress}
						touched={formik.touched.localMerchantAddress}
						onBlur={formik.handleBlur}
					/>
				</div> */}
				{/* NOT REQUIRED */}
				{/* <p className="sub_label merchant_label">
					{t("text_form.local_merchant_text_3")}
				</p>
				<FormikTextField
					handleChange={formik.handleChange}
					value={formik.values.localMerchantWebpage}
					name="localMerchantWebpage"
					errors={formik.errors.localMerchantWebpage}
					touched={formik.touched.localMerchantWebpage}
					onBlur={formik.handleBlur}
				/> */}

				{/* {
					couponing ? <>
						<p className="sub_label merchant_label">
							{t("text_form.phoneNumber")}
						</p>
						<FormikInput
							handleChange={formik.handleChange}
							value={formik.values.phoneNumber}
							name="phoneNumber"
							htmlFor={"phoneNumber"}
							errors={formik.errors.phoneNumber}
							touched={formik.touched.phoneNumber}
							onBlur={formik.handleBlur} 
							disabled={false}
						/>
					</> : 
						null
				} */}

				{stateValues[0].country.length === 2
					? <Checkbox
						style={{marginTop: "15px", marginLeft: "3px"}}
						checked={formik.values.multipleMerchant}
						onChange={() => {
							formik.setFieldValue(
								"multipleMerchant",
								!formik.values.multipleMerchant,
								true
							)
						}}
					>
						{t("text_form.merchant_title_countries")}
					</Checkbox>
					: null
				}

				{formik.values.multipleMerchant
					? <>
						<div style={{height: "10px"}} />
						<FormikTextField
							handleChange={formik.handleChange}
							value={formik.values.merchantDE}
							name="merchantDE"
							errors={formik.errors.merchantDE}
							touched={formik.touched.merchantDE}
							onBlur={formik.handleBlur}
						/>
					</>
					: null
				}


			</div>

			{/*CONDITIONS*/}
			{!couponing &&
				<>
					<div
						onMouseEnter={() => dispatch(setTooltip("conditions"))}
						onMouseLeave={() => dispatch(setTooltip(""))}
						data-testid="conditions_title"
					>
						<p className="simple_label">
							{t("text_form.conditions_title")}
						</p>
					</div>
					<div
						style={{marginBottom: "20px"}}
						onMouseEnter={() => dispatch(setTooltip("additionalConditions"))}
						onMouseLeave={() => dispatch(setTooltip(""))}
						data-testid="additionalConditions_title"
					>
						<p className="sub_label">
							{t("text_form.local_conditions_more")}
						</p>
						<FormikTextField
							handleChange={formik.handleChange}
							value={formik.values.additionalConditions}
							name="additionalConditions"
							errors={formik.errors.additionalConditions}
							touched={formik.touched.additionalConditions}
							onBlur={formik.handleBlur}
						/>

						{stateValues[0].country.length === 2
							? <Checkbox
								style={{marginTop: "15px", marginLeft: "3px"}}
								checked={formik.values.multipleAdditionalConditions}
								onChange={() => {
									formik.setFieldValue(
										"multipleAdditionalConditions",
										!formik.values.multipleAdditionalConditions,
										true
									)
								}}
							>
								{t("text_form.conditions_more_countries")}
							</Checkbox>
							: null
						}


						{formik.values.multipleAdditionalConditions
							? <>
								<div style={{height: "10px"}} />
								<FormikTextField
									handleChange={formik.handleChange}
									value={formik.values.additionalConditionsDE}
									name="additionalConditionsDE"
									errors={formik.errors.additionalConditionsDE}
									touched={formik.touched.additionalConditionsDE}
									onBlur={formik.handleBlur}
								/>
							</>
							: null
						}
					</div>
				</>
			}

			{/*DESCRIPTION*/}
			<div
				style={{marginBottom: "20px"}}
				onMouseEnter={() => dispatch(setTooltip("description"))}
				onMouseLeave={() => dispatch(setTooltip(""))}
				data-testid="description_title"
			>
				<p className="simple_label">
					{`3. ${t("text_form.description_title")}`}
				</p>
				<p className="sub_label">
					{t("text_form.description_text")}
				</p>
				<FormikTextField
					handleChange={formik.handleChange}
					value={formik.values.description}
					name="description"
					errors={formik.errors.description}
					touched={formik.touched.description}
					onBlur={formik.handleBlur}
				/>

				{stateValues[0].country.length === 2
					? <Checkbox
						style={{marginTop: "15px", marginLeft: "3px"}}
						checked={formik.values.multipleDescription}
						onChange={() => {
							formik.setFieldValue(
								"multipleDescription",
								!formik.values.multipleDescription,
								true
							)
						}}
					>
						{t("text_form.description_title_countries")}
					</Checkbox>
					: null
				}


				{formik.values.multipleDescription
					? <>
						<div style={{height: "10px"}} />
						<FormikTextField
							handleChange={formik.handleChange}
							value={formik.values.descriptionDE}
							name="descriptionDE"
							errors={formik.errors.descriptionDE}
							touched={formik.touched.descriptionDE}
							onBlur={formik.handleBlur}
						/>
					</>
					: null
				}

			</div>

			{/*HIGHLIGHTS*/}
			<div
				onMouseEnter={() => dispatch(setTooltip("highlights"))}
				onMouseLeave={() => dispatch(setTooltip(""))}
				style={{position: "relative", marginBottom: "20px"}}
				data-testid="highlights_title"
			>
				<p className="simple_label">
					{`4. ${t("text_form.highlights_title")}`}
				</p>
				<p className="sub_label">
					{t("text_form.highlights_text")}
				</p>
				<FormikTextField
					rows={6}
					handleChange={formik.handleChange}
					value={formik.values.highlights}
					name="highlights"
					errors={formik.errors.highlights}
					touched={formik.touched.highlights}
					onBlur={formik.handleBlur}
				/>

				{stateValues[0].country.length === 2
					? <Checkbox
						style={{marginTop: "15px", marginLeft: "3px"}}
						checked={formik.values.multipleHighlights}
						onChange={() => {
							formik.setFieldValue(
								"multipleHighlights",
								!formik.values.multipleHighlights,
								true
							)
						}}
					>
						{t("text_form.highlights_title_countries")}
					</Checkbox>
					: null
				}


				{formik.values.multipleHighlights
					? <>
						<div style={{height: "10px"}} />
						<FormikTextField
							handleChange={formik.handleChange}
							value={formik.values.highlightsDE}
							name="highlightsDE"
							errors={formik.errors.highlightsDE}
							touched={formik.touched.highlightsDE}
							onBlur={formik.handleBlur}
						/>
					</>
					: null
				}


				{subErrors.highlights && !formik.errors.highlights && formik.touched.highlights
					? <p className="input_error">{subErrors.highlights}</p>
					: null
				}
				{subErrors.highlights && !formik.errors.highlightsDE && formik.touched.highlightsDE
					? <p className="input_error">{subErrors.highlights}</p>
					: null
				}
			</div>

			{/*TEXT*/}
			<div
				style={{marginBottom: "20px"}}
				onMouseEnter={() => dispatch(setTooltip("text"))}
				onMouseLeave={() => dispatch(setTooltip(""))}
				data-testid="texts_title"
			>
				<p className="simple_label">
					{`5. ${t("text_form.text_title")}`}
				</p>
				<p className="sub_label">
					{t("text_form.text_text")}
				</p>
				<FormikTextField
					rows={15}
					handleChange={formik.handleChange}
					value={formik.values.text}
					name="text"
					errors={formik.errors.text}
					touched={formik.touched.text}
					onBlur={formik.handleBlur}
				/>

				{stateValues[0].country.length === 2
					? <Checkbox
						style={{marginTop: "15px", marginLeft: "3px"}}
						checked={formik.values.multipleText}
						onChange={() => {
							formik.setFieldValue(
								"multipleText",
								!formik.values.multipleText,
								true
							)
						}}
					>
						{t("text_form.text_title_countries")}
					</Checkbox>
					: null
				}


				{formik.values.multipleText
					? <>
						<div style={{height: "10px"}} />
						<FormikTextField
							handleChange={formik.handleChange}
							value={formik.values.textDE}
							name="textDE"
							errors={formik.errors.textDE}
							touched={formik.touched.textDE}
							onBlur={formik.handleBlur}
						/>
					</>
					: null
				}
			</div>
		</form>
	)
}

const mapStateToProps = (state: RootState) => {
	return {
		tooltip: state.tooltip,
		stateValues: state.steps.values
	}
}

export default connect(mapStateToProps, null)(LocalTextForm)
